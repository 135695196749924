import styled from '@emotion/styled'
import { TextField } from '@mui/material'

export const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: transparent;
  }

  .MuiFormLabel-root {
    display: none;
  }
`
